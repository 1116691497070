<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate"
              >Pesanan Produk</span
            >
          </span>
          <div class="text-nowrap order-md-last">
            <a class="btn px-0 d-inline-block mr-3">
              <i class="fa fa-share-alt text-light"></i>
            </a>
            <a class="btn px-0 d-inline-block">
              <i class="fas fa-pencil-alt text-light"></i>
            </a>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header" v-if="state.finish">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="
              'background-image: url(' + state.url + state.data.photo + ');'
            "
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.data.name }}</p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <p class="mb-1 small text-muted">Jumlah Pesanan Aktif</p>
              <p class="mb-0 text-main">{{ state.jumlah_pesanan }} pcs</p>
            </div>
            <!-- .col -->
            <div class="col-6 text-right text-md-left">
              <p class="mb-1 small text-muted">Pembeli / PO Grup</p>
              <p class="mb-0">
                {{ state.jumlah_pembeli }} org / {{ state.jumlah_po }} grup
              </p>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <hr class="no-side-margin" />

          <div class="row mt-3 mb-2">
            <div class="col-12">
              <select class="form-control form-control-sm" id="sel1" required>
                <option value="0">Semua PO Grup Aktif...</option>
                <option value="1">SG</option>
                <option value="2">PB</option>
              </select>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="input-group input-group-sm mb-3">
            <input
              type="text"
              id="searchInput"
              class="form-control"
              placeholder="Cari..."
            />
            <div class="input-group-append">
              <span class="input-group-text bg-transparent"
                ><i class="fa fa-search"></i
              ></span>
            </div>
          </div>

          <div class="small text-truncate">
            Daftar Pesanan Aktif {{ state.data.name }}:
          </div>

          <div id="resultList" class="row mt-3" v-if="state.pesananExist">
            <div
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              v-for="pes in state.pesanan"
              :key="pes.id"
            >
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="row no-gutters">
                    <div class="col-12 p-2 pl-3">
                      <span
                        class="
                          card-title
                          text-main
                          font-weight-bold
                          mb-1
                          mt-1
                          text-truncate
                        "
                        >{{ pes.pelanggan.prefix }}
                        {{ pes.pelanggan.name }}</span
                      >
                      - <span class="text-body">{{ pes.detail.length }} pcs</span>
                      <p class="card-text mt-1 mb-0 text-truncate small">
                        SG, E1-77 GV - {{ pes.po.name }}
                      </p>
                      <p class="card-text mb-0 text-truncate">
                        <small class="text-muted"
                          >Total: {{ pes.detail.length }} pesanan, {{ sumJumlah(pes.detail) }} pcs,
                          <span class="currency"
                            >Rp. {{ sumTotal(pes.detail) }}</span
                          ></small
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <router-link
            to="/jual/pesanan/tambah"
            id="btn-float"
            class="
              bg-warning
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
            "
          >
            <div class="d-flex justify-content-center mt-3">
              <i class="fas fa-plus" style="font-size: 25px"></i>
            </div>
          </router-link>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      id: "",
      data: {},
      url: process.env.VUE_APP_API_URL,
      finish: false,
      jumlah_pesanan: 0,
      jumlah_pembeli: 0,
      jumlah_po: 0,
      pesanan: {},
      pesananExist: false,
    });
    const loadData = async () => {
      await axios.get("/api/seller/produk/" + state.id).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
        }
        state.finish = true;
      });
    };
    const loadPesanan = async () => {
      await axios
        .get("/api/seller/produk/getPesanan/" + state.id)
        .then((res) => {
          if (res.data.success) {
            state.pesanan = res.data.data;
            if (res.data.exist) {
              state.pesananExist = true;
            }
          }
        });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
      loadPesanan();
    });
    const changeMoney = (dt) => {
      let cur = Math.ceil(Number(dt));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];
      return ret;
    };
    const sumJumlah = (detail) => {
        let ret = 0;
        detail.map((dt) => {
        ret += dt.jumlah;
        });
        return ret;
    };
    const sumTotal = (detail) => {
      let ret = 0;
      detail.map((dt) => {
        ret += Number(dt.total);
      });
      let cur = Math.ceil(Number(ret));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return mo.split(".")[0];
    };
    const back = () => {
      window.history.back();
    }
    return {
      state,
      changeMoney,
      sumJumlah,
      sumTotal,
      back
    };
  },
};
</script>